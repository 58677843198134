import classNames from 'classnames';
import { Image } from '@alltrails/core';
import logTopModuleClicked from '@alltrails/amplitude/events/logTopModuleClicked';
import Link from '@alltrails/denali/components/Link';
import PageType from '@alltrails/amplitude/enums/PageType';
import PageSectionType from '@alltrails/amplitude/enums/PageSectionType';
import PhotoSlash from '@alltrails/denali/icons/PhotoSlash';
import { maxSlideWidth } from '../carouselConstants';
import styles from './TopModule.module.scss';
import { RiverItem } from '@/types/shared';

type Props = {
  item: RiverItem;

  id: number;
  pageType?: PageType;
};

const TopModuleCard = ({ item, id, pageType }: Props) => (
  <div className={classNames(styles.imageAndTitleContainer)}>
    <Link
      className={styles.linkContainer}
      href={item.url}
      target="_blank"
      testId="top-module-link"
      onClick={() => logTopModuleClicked({ id: String(id), page_type: pageType, selected_content: PageSectionType.TopModules })}
    >
      <div className={styles.itemImageContainer}>
        {item.larger_profile_photo_url ? (
          <Image
            src={item.larger_profile_photo_url}
            width={maxSlideWidth}
            height={maxSlideWidth * 1.5} // aspect ratio
            alt={item.title}
            className={styles.topModuleImage}
            style={{ transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out' }}
            sizes="(max-width: 540px) 100vw, (max-width: 1120px) 50vw, 25vw"
            loading="lazy"
            handleLoadingState
          />
        ) : (
          <div className={styles.noImage}>
            <PhotoSlash color="--color-text-secondary" size="lg" />
          </div>
        )}
      </div>
      <div className={styles.itemTitle}>{item.title}</div>
    </Link>
  </div>
);

export default TopModuleCard;
