import PageSectionType from '../enums/PageSectionType';
import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logTopModuleClickedParams = {
  id: string;
  page_type?: PageType;
  selected_content?: PageSectionType;
};

/**
 * User clicks into a non-trail card displayed in a top module section, directing them into another page. Clicks into trail cards from the Top Module will be logged as Trail Card Clicked events.
 * @param params - Attributes to log with the amplitude event
 * @param params.id - ID for the object from which the top module is seen, could be a trail, park, location, etc.
 * @param params.page_type - The type of content page on which the section was viewed.
 * @param params.selected_content - The type of content page that the user clicks into.
 */
const logTopModuleClicked = ({ id, page_type, selected_content }: logTopModuleClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('top module clicked', { id, 'page type': page_type, 'selected content': selected_content }, callback);
};

export default logTopModuleClicked;
