import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logTopModuleSwipedParams = {
  id: string;
  page_type?: PageType;
};

/**
 * User drags or swipes through the top module carousel. This should only fire once per page in a given session.
 * @param params - Attributes to log with the amplitude event
 * @param params.id - ID for the object from which the top module is seen, could be a trail, park, location, etc.
 * @param params.page_type - The type of content page on which the section was viewed.
 */
const logTopModuleSwiped = ({ id, page_type }: logTopModuleSwipedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('top module swiped', { id, 'page type': page_type }, callback);
};

export default logTopModuleSwiped;
