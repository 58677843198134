/**
 * The location of the show more CTA.
 */
enum ShowMoreLocation {
  Contact = 'contact',
  Facilities = 'facilities',
  GettingThere = 'getting there',
  Overview = 'overview',
  Reviews = 'reviews',
  Tips = 'tips',
  TopTrails = 'top trails',
  TrailDetailsDescription = 'trail details description',
  Waypoints = 'waypoints'
}

export default ShowMoreLocation;
