/**
 * The type of content page on which a web interaction took place.
 */
enum PageSectionType {
  ExploreMap = 'Explore Map',
  FAQ = 'FAQ',
  Guides = 'Guides',
  Highlights = 'Highlights',
  HitTheTrailModule = 'Hit the Trail Module',
  LinkBlock = 'Link Block',
  Reviews = 'Reviews',
  ThreeCardModule = 'Three Card Module',
  TopModules = 'Top Modules',
  TopTrails = 'Top Trails',
  TrailConditions = 'Trail Conditions',
  TrailReviews = 'Trail Reviews',
  VisitorInfo = 'Visitor Info'
}

export default PageSectionType;
