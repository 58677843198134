import PageSectionType from '@alltrails/amplitude/enums/PageSectionType';
import PageType from '@alltrails/amplitude/enums/PageType';
import logWebSectionViewed from '@alltrails/amplitude/events/logWebSectionViewed';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import { RefObject, useEffect, useRef } from 'react';

const useLogWebSectionViewed = (
  ref: RefObject<HTMLElement>,
  sectionType?: PageSectionType,
  pageType?: PageType,
  options?: IntersectionObserverInit
) => {
  const wasViewedRef = useRef<boolean>(false);
  const isVisible = useIsVisible(ref, options);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!isVisible || wasViewedRef.current || !pageType || !sectionType) {
      clearTimeout(timerRef.current);
      return;
    }

    if (isVisible && !wasViewedRef.current) {
      timerRef.current = setTimeout(() => {
        wasViewedRef.current = true;
        logWebSectionViewed({
          page_type: pageType,
          section_type: sectionType
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isVisible]);
};

export default useLogWebSectionViewed;
