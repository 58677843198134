import PageType from '../enums/PageType';
import PhotoLocation from '../enums/PhotoLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logPhotoClickedParams = {
  owned_by_user?: boolean;
  owner_id?: string;
  page_type?: PageType;
  photo_carousel_type?: string;
  photo_id: string;
  photo_location?: PhotoLocation;
};

/**
 * User clicks on a photo in a photo carousel. Specific Trail Details & Park Hero Image Clicked events exist separately. Prior to July 2024 this event had limited volume and may have only represented users clicking into photos from activity cards.
 * @param params - Attributes to log with the amplitude event
 * @param params.owned_by_user - Whether the photo is associated a first party or third party user
 * @param params.owner_id - User ID of the content owner
 * @param params.page_type - The type of content page on which a photo was clicked.
 * @param params.photo_carousel_type - The type of content the photos are associated with on a page, possible values include hero image and reviews.
 * @param params.photo_id - Unique identifier of the photo
 * @param params.photo_location - Duplicative to the owned by user boolean.
 */
const logPhotoClicked = (
  { owned_by_user, owner_id, page_type, photo_carousel_type, photo_id, photo_location }: logPhotoClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'photo clicked',
    {
      'owned by user': owned_by_user,
      'owner id': owner_id,
      'page type': page_type,
      'photo carousel type': photo_carousel_type,
      'photo id': photo_id,
      'photo location': photo_location
    },
    callback
  );
};

export default logPhotoClicked;
