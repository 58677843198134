import PageType from '../enums/PageType';
import ShowMoreLocation from '../enums/ShowMoreLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logShowMoreClickedParams = {
  page_type?: PageType;
  show_more_location?: ShowMoreLocation;
};

/**
 * User clicks 'show more' to expand a section. This is a generic event started in July 2024, specific Trail Details & Review Filters Show More Clicked events predate this generic event.
 * @param params - Attributes to log with the amplitude event
 * @param params.page_type - The type of content page on which a “show more” CTA was clicked.
 * @param params.show_more_location - The location of the show more CTA on a page.
 */
const logShowMoreClicked = ({ page_type, show_more_location }: logShowMoreClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('show more clicked', { 'page type': page_type, 'show more location': show_more_location }, callback);
};

export default logShowMoreClicked;
