import CardLocation from '@alltrails/amplitude/enums/CardLocation';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

export const getAnalyticsCardLocation = (type?: LocationDetailsResponseType): CardLocation | undefined => {
  switch (type) {
    case 'poi':
      return CardLocation.PoiPage;
    case 'park':
      return CardLocation.ParkPage;
    default:
      return CardLocation.LocationPage;
  }
};
