'use client';

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRef, useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import useAuthorization from '@alltrails/context/hooks/useAuthorization';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import useIntersectionObserver from '@alltrails/shared/hooks/useIntersectionObserver';
import PageType from '@alltrails/amplitude/enums/PageType';
import { useMediaQuery } from '@alltrails/core';
import { useExposureEvent } from '@alltrails/experiments';
import { LocationMapProvider } from '../LocationMap/LocationMapContext';
import PageSection from '../layouts/PageSection';
import styles from './ResponsivePageLayout.module.scss';
import { type LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';
import { LocationDetailsApiResponse } from '@/types/locationDetailsPages/shared';
import { SubLocationDetailsApiResponse } from '@/types/subLocationListPages/shared';
import { useSelector } from '@/utils/redux';

const SEOToolbar = dynamic(() => import('@alltrails/di-tooling').then(module => module.SEOToolbar), {
  ssr: false
});

const MAX_MOBILE_LCP_HEIGHT = '46vh';
const threshold = Array.from({ length: 101 }, (_, i) => i / 100);
const headerHeight = 64;
const initialMapTop = 84;

type ResponsivePageLayoutProps = {
  lowerContent: React.ReactNode;
  imageCarousel: React.ReactNode;
  titleAndStats: React.ReactNode;
  description: React.ReactNode;
  breadcrumbs: React.ReactNode;
  mobileButtons: React.ReactNode;
  desktopButtons: React.ReactNode;
  map: React.ReactNode;
  topResultsList: React.ReactNode;
  locationTitle?: string;
  details: LocationDetailsApiResponse | SubLocationDetailsApiResponse;
  listItemName?: string;
  addBottomMargin?: boolean;
  hasZeroImages?: boolean;
  shouldRenderPhotoGrid?: boolean;
  pageType?: PageType;
};

const ResponsivePageLayout = ({
  details,
  lowerContent,
  imageCarousel,
  titleAndStats,
  description,
  breadcrumbs,
  mobileButtons,
  desktopButtons,
  map,
  topResultsList,
  locationTitle,
  pageType,
  listItemName,
  addBottomMargin,
  hasZeroImages,
  shouldRenderPhotoGrid
}: ResponsivePageLayoutProps) => {
  const desktopStickyHeaderRef = useRef<HTMLDivElement>(null);
  const mobileContentRef = useRef<HTMLDivElement>(null);
  const imageScrollRef = useRef<HTMLDivElement>(null);
  const mobileLcpRef = useRef<HTMLDivElement>(null);
  const stickyTitleRef = useRef<HTMLDivElement>(null);
  const [showTabletStickyHeader, setShowTabletStickyHeader] = useState(false);
  const [distanceToTop, setDistanceToTop] = useState(84);
  const [showMobileStickyBar, setShowMobileStickyBar] = useState(false);
  const [showStickyTitle, setShowStickyTitle] = useState(false);
  const [scheduledAnimationFrame, setScheduledAnimationFrame] = useState(false);
  const isMobile = useIsMobileSizedScreen();
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'locations:manage' });
  const isDataIntegrityCTR = hasPermission({ permission: 'areas:manage' });
  const isMobileBrowser = useSelector((state: { context: { mobileBrowser: boolean } }) => state.context?.mobileBrowser);
  const isTabletOrMobile = useMediaQuery(`(max-width: 1120px)`, isMobileBrowser);
  const sendVisitorInfoExposure = useExposureEvent('web-disco-rm-location-page-visitor-info-2024-12');

  useEffect(() => {
    if (pageType === PageType.ParkPage) {
      sendVisitorInfoExposure();
    }
  }, [sendVisitorInfoExposure, pageType]);

  const mobileContentObserver = useIntersectionObserver(
    ([entry]) => {
      setShowMobileStickyBar(entry.boundingClientRect.top < 0);
    },
    { threshold: 1 }
  );

  const stickyTitleObserver = useIntersectionObserver(
    ([entry]) => {
      setShowStickyTitle(entry.boundingClientRect.top < 0);
    },
    { threshold }
  );

  useEffect(() => {
    const mobileContentSection = mobileContentRef.current;
    const stickyTitleSection = stickyTitleRef.current;

    if (mobileContentObserver && mobileContentSection) {
      mobileContentObserver.observe(mobileContentSection);
    }

    if (stickyTitleObserver && stickyTitleSection) {
      stickyTitleObserver.observe(stickyTitleSection);
    }

    return () => {
      if (mobileContentObserver) mobileContentObserver.disconnect();
      if (stickyTitleObserver) stickyTitleObserver.disconnect();
    };
  }, [mobileContentObserver, stickyTitleObserver]);

  const handleShowTabletDesktopStickyHeader = useCallback(() => {
    if (desktopStickyHeaderRef?.current) {
      const newDistanceToTop = desktopStickyHeaderRef.current.getBoundingClientRect().top;
      if (newDistanceToTop > 2) {
        // vertically center the map as the user scrolls:
        if (isAdmin) {
          setDistanceToTop(
            initialMapTop - (desktopStickyHeaderRef.current.getBoundingClientRect().top - headerHeight) / 2 + headerHeight + headerHeight / 2
          );
        } else {
          setDistanceToTop(initialMapTop - (desktopStickyHeaderRef.current.getBoundingClientRect().top - headerHeight) / 2);
        }
      }

      if (newDistanceToTop < initialMapTop && !showTabletStickyHeader) {
        setShowTabletStickyHeader(true);
      } else if (newDistanceToTop >= initialMapTop && showTabletStickyHeader) {
        setShowTabletStickyHeader(false);
      }
      setScheduledAnimationFrame(false);
    }
  }, [isAdmin, showTabletStickyHeader]);

  useEffect(() => {
    const onScroll = () => {
      if (scheduledAnimationFrame) {
        return;
      }

      setScheduledAnimationFrame(true);
      requestAnimationFrame(handleShowTabletDesktopStickyHeader);
    };

    if (!isMobile) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    const onScroll = () => {
      if (scheduledAnimationFrame) {
        return;
      }

      setScheduledAnimationFrame(true);
      requestAnimationFrame(() => {
        if (mobileLcpRef.current) {
          // Zooming effect on image while scrolling up
          const scrollDepth = imageScrollRef.current?.getBoundingClientRect().top ?? 0;
          mobileLcpRef.current.style.height = `calc(${MAX_MOBILE_LCP_HEIGHT} + ${scrollDepth < 1 ? scrollDepth * -0.2 : 0}px`;
          setScheduledAnimationFrame(false);
        }
      });
    };

    if (isMobile && !hasZeroImages) {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, scheduledAnimationFrame, hasZeroImages]);

  const hasAdminToolbar =
    details &&
    details.shareObject &&
    details.shareObject.type !== 'point_of_interest' &&
    (isAdmin || (isDataIntegrityCTR && details.shareObject.type === 'area'));

  return (
    <LocationMapProvider areas={!!details && 'subLocations' in details ? details?.subLocations : undefined} trails={details.initialTrailResults}>
      {hasAdminToolbar && (
        <div className={styles.adminToolbarContainer}>
          <div className={styles.adminToolbar}>
            <SEOToolbar shareObject={details?.shareObject} />
          </div>
        </div>
      )}
      <div
        className={classNames(styles.breadcrumbsRowContainer, {
          [styles.stickyBreadcrumbBar]: showTabletStickyHeader,
          [styles.hasAdminToolbar]: hasAdminToolbar
        })}
      >
        <div className={styles.breadcrumbsRow}>
          <div className={classNames(styles.breadcrumbs, showTabletStickyHeader && styles.hideBreadcrumbs)}>{breadcrumbs}</div>
          {/* The intersection observer that dictates showStickyTitle isn't perfect, 
        so also check showTabletStickyHeader to make sure we don't overlap the sticky title with the breadcrumbs */}
          <div className={classNames(styles.stickyLocationTitle, showTabletStickyHeader && showStickyTitle && styles.showStickyLocationTitle)}>
            {locationTitle}
          </div>
          <div className={classNames(styles.desktopButtons, showTabletStickyHeader && styles.tabletStickyHeader)}>{desktopButtons}</div>
        </div>
      </div>
      <div
        className={classNames(
          styles.stickyBar,
          { [styles.showStickyBar]: showMobileStickyBar && isMobile },
          { [styles.hasAdminToolbar]: hasAdminToolbar }
        )}
      >
        {mobileButtons}
      </div>
      <div ref={desktopStickyHeaderRef}>
        <PageSection containerClassName={styles.mainPageSection} hasDividerTop={false}>
          <div className={styles.responsiveMainColumn}>
            {!isMobile ? <div ref={stickyTitleRef}> {titleAndStats}</div> : null}
            <div
              className={classNames(styles.imageAndButtonsContainer, {
                [styles.noImages]: hasZeroImages,
                [styles.photoGrid]: shouldRenderPhotoGrid
              })}
            >
              <div
                className={classNames(styles.imageContainer, {
                  [styles.noImages]: hasZeroImages,
                  [styles.photoGrid]: shouldRenderPhotoGrid
                })}
                ref={mobileLcpRef}
                style={{ maxHeight: isMobile && !hasZeroImages ? `min(calc(${MAX_MOBILE_LCP_HEIGHT} * 1.2), 480px)` : undefined }}
              >
                {imageCarousel}
              </div>
              <div className={styles.mobileButtons}>{mobileButtons}</div>
            </div>
            <div
              className={classNames(styles.wrapperForMobile, {
                [styles.noImages]: hasZeroImages
              })}
            >
              <div ref={mobileContentRef} style={{ height: 10 }} />
              {/* pseudo element used to track scroll position of photo element if it were not sticky */}
              <div ref={imageScrollRef} style={{ width: '1px', height: MAX_MOBILE_LCP_HEIGHT, position: 'absolute', bottom: '100%' }} />
              {isMobile && <div ref={stickyTitleRef}> {titleAndStats} </div>}
              {description}
              <div className={styles.paddedSection}>
                <h2 className={styles.resultsHeader}>{details['h2SeoTest'] || listItemName || <FormattedMessage defaultMessage="Top trails" />}</h2>
                {!('subLocations' in details) && <div className={styles.mobileTabletMap}>{isTabletOrMobile && map}</div>}
                <div className={styles.resultsList}>{topResultsList}</div>
                {!!('subLocations' in details) && <div className={styles.mobileTabletMap}>{isTabletOrMobile && map}</div>}
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.desktopMapColumn, {
              [styles.hasAdminToolbar]: hasAdminToolbar
            })}
            style={{ top: distanceToTop }}
          >
            {!isTabletOrMobile && map}
          </div>
        </PageSection>
      </div>
      <div className={classNames(styles.content, { [styles.addBottomMargin]: addBottomMargin })}>{lowerContent}</div>
    </LocationMapProvider>
  );
};

export default ResponsivePageLayout;
