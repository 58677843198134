'use client';

import { AlgoliaTrail } from '@alltrails/trails';
import { logError, useMediaQuery } from '@alltrails/core';
import { useReduxListItems } from '@alltrails/lists';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Button from '@alltrails/denali/components/Button';
import { useCallback, useMemo, useRef, useState } from 'react';
import logExploreMoreTrailsClicked from '@alltrails/amplitude/events/logExploreMoreTrailsClicked';
import logShowMoreClicked from '@alltrails/amplitude/events/logShowMoreClicked';
import ShowMoreLocation from '@alltrails/amplitude/enums/ShowMoreLocation';
import PageType from '@alltrails/amplitude/enums/PageType';
import PageSectionType from '@alltrails/amplitude/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { ExploreServiceFilters, useGetLocationDataMutation } from '@alltrails/explore-service';
import { useLocationMap } from '../LocationMap/LocationMapContext';
import TopTrailsCard from './TopTrailsCard';
import styles from './TopResults.module.scss';
import { getAnalyticsCardLocation } from '@/utils/getAnalyticsCardLocation';
import { FilterConfig, LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';
import { getFilters, getLocationId, getPlaceType } from '@/utils/locationDataUtil';
import { useSelector } from '@/utils/redux';

type Props = {
  trails: AlgoliaTrail[];
  exploreLink?: string;
  type?: LocationDetailsResponseType;
  totalTrails: number;
  filterConfig: FilterConfig;
  pageType?: PageType;
};

const TopTrails = ({ trails, exploreLink, type, totalTrails, filterConfig }: Props) => {
  const pageType = getAnalyticsPageType(type);
  const reduxListsAnalyticsInfo = useMemo(
    () => ({
      page: PageStrings.SEO_PAGE,
      detailedCardLocation: getAnalyticsCardLocation(type)
    }),
    [type]
  );
  const { isFavorite, handleFavoriteClick, listModal, signUpModal } = useReduxListItems(undefined, undefined, reduxListsAnalyticsInfo);
  const { currentPageForTrailsQuery, handleGetMoreTrails, handleSetTrailsToRender, trailsToRender } = useLocationMap();
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.Highlights, pageType, { threshold: 0.1 });
  const [getLocationData] = useGetLocationDataMutation();
  const isMobileBrowser = useSelector((state: { context: { mobileBrowser: boolean } }) => state.context?.mobileBrowser);
  const isTabletOrMobile = useMediaQuery(`(max-width: 1120px)`, isMobileBrowser);
  const [isShowMoreClicked, setIsShowMoreClicked] = useState<boolean>(false);

  const handleClickExploreMore = useCallback(() => {
    if (pageType) {
      logExploreMoreTrailsClicked({
        page_type: pageType
      });
    }
  }, [pageType]);

  const onShowMoreClick = useCallback(async () => {
    if (pageType) logShowMoreClicked({ page_type: pageType, show_more_location: ShowMoreLocation.TopTrails });
    setIsShowMoreClicked(true);
    const placeType = getPlaceType(filterConfig);
    const locationId = getLocationId(filterConfig);
    const locationIdNumber = locationId ? Number(locationId) : undefined;
    const features = getFilters(filterConfig, 'features');
    const activities = getFilters(filterConfig, 'activities');
    const suitability = getFilters(filterConfig, 'access');

    if (placeType && locationIdNumber && !isNaN(locationIdNumber)) {
      const filters: ExploreServiceFilters = {};
      // Any casts to go from string[] to the more specific string[] types w/o a big refactor right now
      if (activities) filters.activity = activities as any;
      if (features) filters.feature = features as any;
      if (suitability) filters.suitability = suitability as any;

      try {
        const response = await getLocationData({
          filters,
          limit: 10,
          location: { id: locationIdNumber, placeType },
          page: currentPageForTrailsQuery,
          sort: 'most_popular',
          recordAttributesToRetrieve: [
            '_geoloc',
            '_cluster_geoloc',
            'area_id',
            'area_name',
            'area_type',
            'avg_rating',
            'difficulty_rating',
            'duration_minutes',
            'ID',
            'lat',
            'length',
            'lng',
            'name',
            'objectID',
            'type',
            'verified_map_id',
            'slug',
            'description',
            'estimated_time_to_complete'
          ]
        }).unwrap();
        handleSetTrailsToRender(response.searchResults);
        handleGetMoreTrails();
      } catch (error) {
        logError(new Error(`Error fetching location data: ${error}`));
        setIsShowMoreClicked(false);
      }
    }
  }, [pageType, filterConfig, getLocationData, currentPageForTrailsQuery, handleSetTrailsToRender, handleGetMoreTrails]);

  const formatttedMaxTrails = <FormattedNumber value={trailsToRender?.length} />;
  const formattedTotalTrails = <FormattedNumber value={totalTrails} />;

  return (
    <div ref={ref} className={styles.topResultsContainer}>
      {trailsToRender.map((trail, idx) => {
        return (
          <TopTrailsCard
            key={trail.ID}
            trail={trail}
            ranking={idx + 1}
            showBorder={idx !== trails.length - 1}
            isFavorite={isFavorite('trail', trail.ID)}
            onFavoriteClick={handleFavoriteClick}
            isShowMoreClicked={isShowMoreClicked}
          />
        );
      })}
      {totalTrails > 10 && trailsToRender?.length < totalTrails && (
        <div className={styles.showMoreSection}>
          <Button
            className={styles.showMoreButton}
            text={intl.formatMessage({ defaultMessage: 'Show more' })}
            variant="primary"
            testId="show-more-button"
            onClick={onShowMoreClick}
          />
          <div className={styles.showing}>
            <FormattedMessage
              defaultMessage="Showing results {min}–{max} of {total}"
              values={{ min: 1, max: formatttedMaxTrails, total: formattedTotalTrails }}
            />
          </div>
        </div>
      )}
      {exploreLink && !isTabletOrMobile && (
        <Button
          className={styles.exploreMore}
          text={intl.formatMessage({ defaultMessage: 'Explore more trails' })}
          testId="trail-list-explore-more"
          linkInfo={{ href: exploreLink, outsideOfMugen: true }}
          onClick={handleClickExploreMore}
        />
      )}
      {listModal}
      {signUpModal}
    </div>
  );
};

export default TopTrails;
