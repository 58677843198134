import MapLocation from '../enums/MapLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logPrintMapButtonClickedParams = {
  map_location?: MapLocation;
  trail_id: number;
};

/**
 * User taps 'Print Map' icon on the explore map page, trail map page, activity/recording map, or saved map.
 * @param params - Attributes to log with the amplitude event
 * @param params.map_location - The location of a map when it is interacted with, such as printing. (e.g. explore map page, trail map page, activity/recording page, saved map.
 * @param params.trail_id - The identifier for the trail, park, location, etc. associated with the map in view.
 */
const logPrintMapButtonClicked = ({ map_location, trail_id }: logPrintMapButtonClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('print map button clicked', { 'map location': map_location, 'trail id': trail_id }, callback);
};

export default logPrintMapButtonClicked;
