/**
 * The type of content page on which a web interaction took place.
 */
enum PageType {
  LocationPage = 'location page',
  ParkPage = 'park page',
  PoiPage = 'poi page',
  TrailPage = 'trail page'
}

export default PageType;
