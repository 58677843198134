/**
 * Locations in the app or web page where content can be reported.
 */
enum ReportLocation {
  POIPage = 'POI page',
  ActivityDetails = 'activity details',
  ContributeWebPage = 'contribute web page',
  Feed = 'feed',
  FollowingFeed = 'following feed',
  List = 'list',
  LocationFeed = 'location feed',
  LocationWebPage = 'location web page',
  MapDetails = 'map details',
  PhotoGallery = 'photo gallery',
  Portal = 'portal',
  ThirdPartyProfile = 'third party profile',
  TrailDetails = 'trail details'
}

export default ReportLocation;
