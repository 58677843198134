import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logExploreMoreTrailsClickedParams = {
  page_type?: PageType;
};

/**
 * User clicks “explore more trails” CTA.
 * @param params - Attributes to log with the amplitude event
 * @param params.page_type - The type of content page on which the map is displayed.
 */
const logExploreMoreTrailsClicked = ({ page_type }: logExploreMoreTrailsClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('explore more trails clicked', { 'page type': page_type }, callback);
};

export default logExploreMoreTrailsClicked;
