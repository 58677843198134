import PageType from '../enums/PageType';
import PhotoCarouselType from '../enums/PhotoCarouselType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logPhotoCarouselSwipedParams = {
  carousel_item_id: string;
  page_type?: PageType;
  photo_carousel_type?: PhotoCarouselType;
};

/**
 * User engages with a photo carousel to advance to the next image. Event only logs 1x for any number of scrolls in a single carousel, regardless of how many photos are viewed. On desktop web this is a click interaction versus a swipe on mobile web.
 * @param params - Attributes to log with the amplitude event
 * @param params.carousel_item_id - ID for the object the images are associated with, could be a trail, review, park, etc.
 * @param params.page_type - The type of content page on which a photo carousel was scrolled through.
 * @param params.photo_carousel_type - The type of content the photos are associated with on a page, possible values include hero image and reviews.
 */
const logPhotoCarouselSwiped = ({ carousel_item_id, page_type, photo_carousel_type }: logPhotoCarouselSwipedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'photo carousel swiped',
    { 'carousel item id': carousel_item_id, 'page type': page_type, 'photo carousel type': photo_carousel_type },
    callback
  );
};

export default logPhotoCarouselSwiped;
