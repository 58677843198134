import PageSectionType from '../enums/PageSectionType';
import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logWebSectionViewedParams = {
  page_type?: PageType;
  section_type?: PageSectionType;
};

/**
 * A section of a page comes into view for the user. This fires when any part of the section goes into view, not when the section is 100% visible and is intended for scroll depth & section engagement analysis.
 * @param params - Attributes to log with the amplitude event
 * @param params.page_type - The type of content page from which the section was viewed.
 * @param params.section_type - The type of content that came into view as a subsection of a web page: Highlights, Guides, Trail Reviews, FAQ, Top Modules, Link Block
 */
const logWebSectionViewed = ({ page_type, section_type }: logWebSectionViewedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('web section viewed', { 'page type': page_type, 'section type': section_type }, callback);
};

export default logWebSectionViewed;
