import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logBreadcrumbLinkClickedParams = {
  click_text?: string;
  page_type: PageType;
  position_available: string;
  url: string;
};

/**
 * User clicks a link into an explore page from the breadcrumb.
 * @param params - Attributes to log with the amplitude event
 * @param params.click_text - The text that was clicked
 * @param params.page_type - the type of page the user was on when they clicked/tapped the breadcrumb link
 * @param params.position_available - 0 indexed position in a breadcrumb link chain
 * @param params.url - The linked URL of the text
 */
const logBreadcrumbLinkClicked = ({ click_text, page_type, position_available, url }: logBreadcrumbLinkClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'breadcrumb link clicked',
    { 'click text': click_text, 'page type': page_type, 'position available': position_available, url },
    callback
  );
};

export default logBreadcrumbLinkClicked;
