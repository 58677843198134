import PageType from '../enums/PageType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logDirectionsButtonTappedParams = {
  id: number;
  page_type?: PageType;
};

/**
 * Directions button tapped. This is a generic event started in July 2024, specific Trail Details & Park Details Directions Button Tapped events predate this generic event.
 * @param params - Attributes to log with the amplitude event
 * @param params.id - The identifier for the trail, park, location, etc. associated with the map in view.
 * @param params.page_type - The type of content page from which a “directions” CTA was clicked.
 */
const logDirectionsButtonTapped = ({ id, page_type }: logDirectionsButtonTappedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('directions button tapped', { id, 'page type': page_type }, callback);
};

export default logDirectionsButtonTapped;
